import * as React from 'react';
import './vendors.css';

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Avatar, Badge, Button, Card, CardContent, CardHeader, Grid, IconButton, styled } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Public';

import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    rows: GridRowsProp;
    selectionModel:any;
}

const ColoredIcon = styled('span')(({ color }) => ({
  color: color,
}));
  
class Vendors extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();

    constructor(props: any) {
        super(props);
        this.createUser = this.createUser.bind(this);

        this.state = {
            rows: [],
            selectionModel: []
        };
    }

    public  createUser = () => {
        window.location.assign(process.env.PUBLIC_URL+"/createuser");
    };

    componentDidMount(){
        this.adminService.getVendors().then(
            (response) => {
                this.setState({rows:response});
            });
    }

    public render() {

        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ paddingBottom: '30px', paddingTop:'20px' }}>

                        <br/>
                        <div className="roots">
                            <Grid container spacing={3}>
                                {this.state.rows && this.state.rows.map((member) => (
                                <Grid item md={4} key={member.name} style={{width: '100%'}}>
                                <Grid container direction="column" alignItems="center" style={{backgroundColor: "#f5f5f5", padding:'10px', paddingTop: '20px'}}>
                                 
                                  <Grid item >
                                    <div className="names"><strong>{member.company}</strong></div>
                                    <div className="names"><em>{member.service}</em></div>
                                    <List>
                                    { member.phone != '' &&  <ListItem style={{paddingBottom: '4px', paddingTop: '4px'}}>
                                          <ListItemIcon >
                                          <ColoredIcon color="#cf4521">
                                            <PhoneIcon />
                                            </ColoredIcon>
                                          </ListItemIcon>
                                          <ListItemText primary={member.phone} onClick={() => window.open('tel:'+member.phone, '_blank') } />
                                        </ListItem>
                                      }
                                        { member.email != '' &&  <ListItem style={{paddingBottom: '4px', paddingTop: '4px'}}>
                                          <ListItemIcon> 
                                          <ColoredIcon color="#cf4521">
                                            <EmailIcon />
                                            </ColoredIcon>
                                          </ListItemIcon>
                                          <ListItemText primary={member.email} onClick={() => window.open('mailto:'+member.email, '_blank') } />
                                        </ListItem>
                                      }
                                        { member.website != '' &&  <ListItem style={{paddingBottom: '4px', paddingTop: '4px'}}>
                                          <ListItemIcon>
                                          <ColoredIcon color="#cf4521">
                                            <EventIcon />
                                            </ColoredIcon>
                                          </ListItemIcon>
                                          <ListItemText primary={member.website} />
                                        </ListItem>
                                        }
                                      </List>
                                  </Grid>
                                  {/*<Grid item container className="socials" justifyContent="center">

                                    { member.facebook !== "" &&
                                        <Grid item>
                                        <IconButton href={member.facebook}>
                                            <Facebook />
                                        </IconButton>
                                        </Grid>
                                    }

                                    { member.instagram !== "" && 
                                    <Grid item>
                                      <IconButton href={member.instagram}>
                                        <Instagram />
                                      </IconButton>
                                    </Grid>
                                    }

                                    {member.linkedin !== "" && 
                                    <Grid item>
                                      <IconButton href={member.linkedin}>
                                        <LinkedIn />
                                      </IconButton>
                                    </Grid>
                                    }
                                    {member.twitter !== "" && 
                                    <Grid item>
                                      <IconButton href={member.twitter}>
                                        <Twitter />
                                      </IconButton>
                                    </Grid>
                                    }
                                    {member.youtube !== "" && 
                                    <Grid item>
                                      <IconButton href={member.youtube}>
                                        <Youtube />
                                      </IconButton>
                                    </Grid>
                                    }

                                    {member.website !== "" && 
                                    <Grid item>
                                      <IconButton href={member.website}>
                                        <Website />
                                      </IconButton>
                                    </Grid>
                                    }

                                  </Grid>*/}
                                </Grid>
                              </Grid>

                                
                                ))}
                            </Grid>
                            </div>

                        
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default Vendors;