import * as React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import darkLogo from './../../assets/hubLogo.png'
import './resources.css';
import FolderTree  from 'react-folder-tree';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateResources {
    numPages: number;
    pageNumber: number;
    fileUrl : string;
    fileName: string;
    width: any;
}


const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };

pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Resources extends React.Component <{}, IStateResources> {
    
    constructor(props: any) {
        super(props);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.downloadfile = this.downloadfile.bind(this);
        this.state = {
            numPages: 1,
            pageNumber: 1,
            fileUrl: 'pdf/Direct_Deposit_Authorization.pdf',
            fileName: 'Direct_Deposit_Authorization.pdf',
            width: null,
        };
    }

    
    public onDocumentLoadSuccess = ({numPages}: {numPages: number})  =>{
        console.log(numPages);
        this.setState({numPages : numPages});
      }

    public downloadfile = async (downloadfile: string) => {
        try {
            const response = await fetch(downloadfile); // Replace with the actual path to your file
            
            if (!response.ok) {
              throw new Error('File download failed');
            }
            
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = this.state.fileName; // Replace with the desired file name and extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error('Error downloading file:', error);
          }
    }


        

    public render() { 
        
        const data = {
            "name": "RESOURCES",
            "children": [
                { 
                    "name": "FORBES RESOURCES",
                    "children": [
                        {
                            "name": "Direct Deposit Authorization.pdf",
                            "url": "Direct_Deposit_Authorization.pdf"
                        },
                        {
                            "name": "Luxe Buyer Client Engagement Letter.pdf",
                            "url": "Luxe_Buyer_Client_Engagement_Letter.pdf"
                        },
                        
                        {
                            "name": "LUXE Forbes Escrow Instructions Broker Demand.pdf",
                            "url": "UPDATED_LUXE_Forbes_Escrow_Instructions_Broker_Demand.pdf"
                        },
                        {
                            "name": "LUXE Forbes Referral Agreement.pdf",
                            "url": "LUXE_Forbes_Referral_Agreement.pdf"
                        },
                        {
                            "name": "LUXE Forbes W-9 2023.pdf",
                            "url": "LUXE_Forbes_W-9_2023.pdf"
                        },

                        {
                            "name": "Luxe Letterhead.docx",
                            "url": "Luxe_Letterhead.docx"
                        },
                        {
                            "name": "Luxe Letterhead.pdf",
                            "url": "Luxe_Letterhead.pdf"
                        },

                        {
                            "name": "Luxe Letterhead (Orange Logo).pdf",
                            "url": "Luxe_Letterhead_(Orange_Logo).pdf"
                        },
                        
                        {
                            "name": "Luxe Letterhead (Orange Logo).docx",
                            "url": "Luxe_Letterhead_(Orange_Logo).docx"
                        }
                    ],
                    "url": "root/All Cryptos"
                },
                { 
                    "name": "BUYER RESOURCES",
                    "children": [
                    ],
                    "url": "root/All Cryptos"
            },   
            { 
                "name": "SELLER RESOURCES",
                "children": [
                ],
                "url": "root/All Cryptos"
        },             
            
            ]
        }
            
        const addUrl = (node: any) => {
            const fakeUrl = `pdf/${node.url}`;
          if (node.children) {
            node.url = fakeUrl;
            node.children = node.children.map((c:any) => addUrl(c));
          } else {
            node.url = fakeUrl;
          }
          
          return node;
          };
          
          // simulate a download function
          const fakeDownload = (nodeData:any) => {
            this.setState({fileUrl: nodeData.url, fileName: nodeData.name});
            console.log(nodeData.url);
          }
          
          // custom event handler for node name click
          const onNameClick = (nodeData: any) => {
            const {
              // internal data
              path, name, checked, isOpen,
              // custom data
              url, ...whateverRest
            } = nodeData.nodeData;
            fakeDownload({ name, url });
          };
          
          const CustomNodeClickDemo = () => (
            console.log("click")
          );

        return (
            <div className="home">
               <section id="neighborhood_heading" className="cy-custom">
               <MenuPrincipal />  
                <div className="Container-Principal">
                    <div className="item">
                        <FolderTree
                        data={ addUrl(data) }
                        onNameClick={ onNameClick }
                        showCheckbox={ false }
                        />
                    </div>
                    <div className="item">

                        <div id="ResumeContainer" >

                        <p>
                            
                            <button className="btn_prev" disabled={this.state.pageNumber == 1} onClick={() => this.setState(prevState => ({ pageNumber: prevState.pageNumber - 1 }))}>
                                &lt;&lt;
                            </button>
                             Page {this.state.pageNumber} of {this.state.numPages}
                            <button className="btn_prev" disabled={this.state.pageNumber >= this.state.numPages} onClick={() => this.setState(prevState => ({ pageNumber: prevState.pageNumber + 1 }))}>
                                &gt;&gt;
                            </button>
                        </p>
                        <p>
                            
                            <button className="btn_prev"  onClick={() => this.downloadfile(this.state.fileUrl)}>
                            Download
                            </button>
                        </p>

                        <Document file={this.state.fileUrl} onLoadSuccess={this.onDocumentLoadSuccess} options={options}>
                                <Page pageNumber={this.state.pageNumber} className='canvasRender' />
                        </Document>

                        <p>
                            &nbsp;
                        </p>
                        </div>
                    </div>
                </div>
                    
                </section>
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default Resources;