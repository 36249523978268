import * as React from 'react';
import './RegisterUser.css';
import darkLogo from './../../assets/hubLogo.png'
import { Chip, Checkbox, FormControlLabel, TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Avatar, IconButton, Box, Typography, Badge, FormHelperText, InputAdornment, Alert, Divider } from '@mui/material';
import AdminService from '../../services/Admin.service';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/lab/Autocomplete';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    name: string;
    lastname: string;
    title: string;
    email: string;
    website: string;
    Cellphone: string;

    slogan: string;
    abaoutme: string;
    cities: string[];
    lifestyle: string[];

    Facebook: string;
    Instagram: string;
    YouTube: string;
    GoogleMyBusiness: string;
    TikTok: string;
    Twitter: string;
    Linkedln: string;

    requisite1: boolean;

}

class RegisterUser extends React.Component<{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleWebsiteChange = this.handleWebsiteChange.bind(this);

        this.handlesloganChange = this.handlesloganChange.bind(this);
        this.handleabaoutmeChange = this.handleabaoutmeChange.bind(this);
        this.handlecitiesChange = this.handlecitiesChange.bind(this);
        this.handlelifestyleChange = this.handlelifestyleChange.bind(this);

        this.handleFacebookChange = this.handleFacebookChange.bind(this);
        this.handleInstagramChange = this.handleInstagramChange.bind(this);
        this.handleYouTubeChange = this.handleYouTubeChange.bind(this);
        this.handleGoogleMyBusinessChange = this.handleGoogleMyBusinessChange.bind(this);
        this.handleTikTokChange = this.handleTikTokChange.bind(this);
        this.handleTwitterChange = this.handleTwitterChange.bind(this);
        this.handleLinkedlnChange = this.handleLinkedlnChange.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.cancelReg = this.cancelReg.bind(this);

        this.state = {
            name: '',
            lastname: '',
            title: '',
            email: '',
            website: '',
            Cellphone: '',

            slogan: '',
            abaoutme: '',
            cities: [],
            lifestyle: [],
            
            Facebook: '',
            Instagram: '',
            YouTube: '',
            GoogleMyBusiness: '',
            TikTok: '',
            Twitter: '',
            Linkedln: '',
            requisite1: false,
        };
    }

    public handleNameChange = (event: any) => {
        this.setState({ name: event.target.value })
        };
    public handleLastNameChange = (event: any) => {
        this.setState({ lastname: event.target.value })
        };

    public handleTitleChange = (event: any) => {
        this.setState({ title: event.target.value })
    };

    public handleCellphoneChange = (event: any) => {
        this.setState({ Cellphone: event.target.value })
    };

    public handleEmailChange = (event: any) => {
        this.setState({ email: event.target.value })
    };

    public handleWebsiteChange = (event: any) => {
        this.setState({ website: event.target.value })
    };

    public handlesloganChange = (event: any) => {
        this.setState({ slogan: event.target.value })
    };
    public handleabaoutmeChange = (event: any) => {
        this.setState({ abaoutme: event.target.value })
    };

    public handlecitiesChange = (event:any, values:any) => {
        if (values.length <= 9) {
          this.setState({
            cities: values,
          });
        }
      };
    public handlelifestyleChange = (event:any, values:any) => {
        if (values.length <= 9) {
          this.setState({
            lifestyle: values,
          });
        }
    };
    
    public handleFacebookChange = (event: any) => {
        this.setState({ Facebook: event.target.value })
    };
    public handleInstagramChange = (event: any) => {
        this.setState({ Instagram: event.target.value })
    };
    public handleYouTubeChange = (event: any) => {
        this.setState({ YouTube: event.target.value })
    };
    public handleGoogleMyBusinessChange = (event: any) => {
        this.setState({ GoogleMyBusiness: event.target.value })
    };
    public handleTikTokChange = (event: any) => {
        this.setState({ TikTok: event.target.value })
    };
    public handleTwitterChange = (event: any) => {
        this.setState({ Twitter: event.target.value })
    };
    public handleLinkedlnChange = (event: any) => {
        this.setState({ Linkedln: event.target.value })
    };

    public handleSubmit = (event: any) => {
        event.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
           
        };

        this.adminService.modifyUser(data).then(
            (response: any) => {
                window.location.assign(process.env.PUBLIC_URL + "/home");
            });

    }

    public cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL + "/home");
    };



    componentDidMount() {

    }

    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{
                        height: 500, width: '100%', paddingLeft: '50px',
                        paddingRight: '50px', paddingBottom: '50px', paddingTop: '20px'
                    }}>

                        <br />
                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2} sx={{ textAlign: 'left'}}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography variant="h4">
                                        PERSONAL INFORMATION
                                    </Typography>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" xs={6} spacing={2}>
                                    <Grid item>
                                        {!this.state.requisite1 && <Alert severity="info">Please fill in the following fields:</Alert>}
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Name</strong>
                                        </Typography>
                                    </Grid>                          
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="name"
                                                placeholder="Enter your name here"
                                                value={this.state.name}
                                                onChange={this.handleNameChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.name.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                    
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Last Name</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                    <FormControl fullWidth>
                                    <TextField
                                                id="lastname"
                                                placeholder="Enter your last name here"
                                                value={this.state.lastname}
                                                onChange={this.handleLastNameChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.lastname.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                            </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Title</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                    <FormControl fullWidth>
                                    <TextField
                                                id="Title"
                                                placeholder="Enter your title"
                                                value={this.state.title}
                                                onChange={this.handleTitleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.title.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                            </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Cellphone</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                    <FormControl fullWidth>
                                    <TextField
                                                id="Cellphone"
                                                placeholder="Enter your Cellphone"
                                                value={this.state.Cellphone}
                                                onChange={this.handleCellphoneChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.Cellphone.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                            </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Email</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                    <FormControl fullWidth>
                                    <TextField
                                                id="email"
                                                placeholder="Enter your Email"
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.email.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                            </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Personal Website</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                    <FormControl fullWidth>
                                    <TextField
                                                id="website"
                                                placeholder="Enter your Website"
                                                value={this.state.website}
                                                onChange={this.handleWebsiteChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.website.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                            </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography variant="h4">
                                        PERSONAL WEBSITE
                                    </Typography>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" xs={6} spacing={2}>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Slogan</strong>
                                        </Typography>
                                    </Grid>                          
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="slogan"
                                                placeholder="Enter your slogan here"
                                                value={this.state.slogan}
                                                onChange={this.handlesloganChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.slogan.length}/50
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 50,  
                                                    }
                                                    
                                                }}
                                            />
                                            <FormHelperText>Maximum 50 characters</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>About Me</strong>
                                        </Typography>
                                    </Grid>                          
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="aboutme"
                                                placeholder="Enter your about me"
                                                value={this.state.abaoutme}
                                                onChange={this.handleabaoutmeChange}
                                                multiline
                                                rows={4}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="caption" color="textSecondary">
                                                            {this.state.abaoutme.length}/1000
                                                            </Typography>
                                                        </InputAdornment>
                                                    ),
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                            <FormHelperText>Maximum 1000 characters</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Cities</strong>
                                        </Typography>
                                    </Grid>                          
                                    <Grid item>
                                        <FormControl fullWidth>
                                        <Autocomplete
                                        multiple
                                        id="cities"
                                        options={[
                                            'Lima', 'Arequipa', 'Trujillo', 'Chiclayo', 'Piura', 'Cusco', 
                                            'Huancayo', 'Chimbote', 'Iquitos', 'Tacna', 'Juliaca', 'Ayacucho',
                                            // Añade todas las ciudades que quieras aquí
                                          ]}
                                        value={this.state.cities}
                                        onChange={this.handlecitiesChange}
                                        renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label="Cities" placeholder="Add city" />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                        }
                                    />
                                        </FormControl>
                                    </Grid>

                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>LifeStyle</strong>
                                        </Typography>
                                    </Grid>                          
                                    <Grid item>
                                        <FormControl fullWidth>
                                        <Autocomplete
                                        multiple
                                        id="lifestyle"
                                        options={[
                                            'Luxury Homes', 
                                            'New Construction', 
                                            'Farm & Ranch', 
                                            'Condos', 
                                            'Waterfront', 
                                            'Swimming Pool', 
                                            'Other'
                                            ]}
                                            value={this.state.lifestyle}
                                            onChange={this.handlelifestyleChange}
                                            renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="lifestyle" placeholder="Add lifestyle" />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                            }
                                        />
                                        </FormControl>
                                    </Grid>

                                </Grid>   
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Typography variant="h4">
                                        SOCIAL NETWORKS
                                    </Typography>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" xs={6} spacing={2}>
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Facebook</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="facebook"
                                                placeholder="Enter your facebook"
                                                value={this.state.Facebook}
                                                onChange={this.handleFacebookChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid> 
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Instagram</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="Instagram"
                                                placeholder="Enter your Instagram"
                                                value={this.state.Instagram}
                                                onChange={this.handleInstagramChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>  
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Youtube</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="YouTube"
                                                placeholder="Enter your YouTube"
                                                value={this.state.YouTube}
                                                onChange={this.handleYouTubeChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid> 
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Google My Business</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="GoogleMyBusiness"
                                                placeholder="Enter your GoogleMyBusiness"
                                                value={this.state.GoogleMyBusiness}
                                                onChange={this.handleGoogleMyBusinessChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>   
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>TikTok</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="TikTok"
                                                placeholder="Enter your TikTok"
                                                value={this.state.TikTok}
                                                onChange={this.handleTikTokChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid> 
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>Twitter</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="twitter"
                                                placeholder="Enter your twitter"
                                                value={this.state.Twitter}
                                                onChange={this.handleTwitterChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid> 
                                    <Grid item >
                                        <Typography variant="subtitle1" sx={{ display: "inline-block", width: "auto", textAlign: "initial" }}>
                                            <strong>LinkedIn</strong>
                                        </Typography>
                                    </Grid>  
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="linkedin"
                                                placeholder="Enter your linkedin"
                                                value={this.state.Linkedln}
                                                onChange={this.handleLinkedlnChange}
                                                
                                                InputProps={{
                                                    
                                                    inputProps: { 
                                                        maxLength: 1000,  
                                                    }
                                                    
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>                          
                                </Grid>                                    
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                                    <Button variant="contained" style={{margin: '8px',backgroundColor: '#c95c3a'}}>
                                        Registrar
                                    </Button>
                                </Grid>                                
                            </Grid>
                            <Box height="50px" />

                        </form>
                    </div>
                </section>
                <div className="footer-home">
                    LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default RegisterUser;