import * as React from 'react';
import './adminpanel.css';
import darkLogo from './../../assets/hubLogo.png'

import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    name: string;
    email: string;
    password: string;
    role: number;
    active: number;
    title: string;
    facebook: string;
    twitter: string;
    linkedin: string;
    instagram: string;
    avatar: any;
    filename: string;
    youtube: string;
    website: string;
}
  
class CreateUser extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();

    constructor(props: any) {
        super(props);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRoleActive = this.handleRoleActive.bind(this);
        this.handleTitle = this.handleTitle.bind(this);
        this.cancelReg = this.cancelReg.bind(this);
        this.handleAvatarChange = this.handleAvatarChange.bind(this);
        this.state = {
            name : '',
            email: '',
            password: '',
            role: 0,
            active: 1,
            title: '',
            avatar: null,
            facebook: '',
            twitter: '',
            linkedin: '',
            instagram: '',
            filename: '',
            website: '',
            youtube: '',
        };
    }

    public handleNameChange = (event:any) => this.setState({name:event.target.value});
    public handleEmailChange = (event:any) => this.setState({email:event.target.value});
    public handlePasswordChange = (event:any) => this.setState({password:event.target.value});
    public handleRoleChange = (event:any) => this.setState({role:event.target.value});
    public handleRoleActive = (event:any) => this.setState({active:event.target.value});
    public handleTitle = (event:any) => this.setState({title:event.target.value});
    public handleFacebookChange = (event:any) => this.setState({facebook:event.target.value});
    public handleTwitterChange = (event:any) => this.setState({twitter:event.target.value});
    public handleLinkedinChange = (event:any) => this.setState({linkedin:event.target.value});
    public handleInstagramChange = (event:any) => this.setState({instagram:event.target.value});
    public handleYoutubeChange = (event:any) => this.setState({youtube:event.target.value});
    public handleWebsiteChange = (event:any) => this.setState({website:event.target.value});
    public handleSubmit = (event:any) => {
        event.preventDefault();

                const data = {
                    name: this.state.name,
                    email: this.state.email,
                    password: this.state.password,
                    role: this.state.role,
                    active: this.state.active,
                    avatar: this.state.avatar,
                    facebook: this.state.facebook,
                    twitter: this.state.twitter,
                    title: this.state.title,
                    linkedin: this.state.linkedin,
                    instagram: this.state.instagram,
                    filename: this.state.filename,
                    youtube: this.state.youtube,
                    website: this.state.website

                };
            

            this.adminService.createUser(data).then(
                (response: any) => {
                    window.location.assign(process.env.PUBLIC_URL+"/adminpanel");
                });

    }

    public  cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL+"/adminpanel");
    };

    public handleAvatarChange(event :any) {
        const file = event.target.files[0];
        const filename = file.name;
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({avatar : reader.result, filename : filename});
          };
          reader.readAsDataURL(file);
        }
      }

    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '50px', paddingTop:'20px' }}>
                        <h4>Create New User</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/admincategories">ALL CATEGORIES</a>
                      </div>
                      <br/><br/>
                        <form onSubmit={this.handleSubmit}>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <label htmlFor="avatar-upload" style={{ marginBottom: '1rem' }}>Selecciona una imagen de avatar:</label>
                            <input type="file" id="avatar-upload" onChange={this.handleAvatarChange} style={{ display: 'none' }} />
                            <label htmlFor="avatar-upload" style={{ position: 'relative' }}>
                                <Avatar src={this.state.avatar} sx={{ width: '8rem', height: '8rem', border: '2px solid #ccc' }} />
                                <IconButton aria-label="Editar avatar" style={{ position: 'absolute', bottom: '0.25rem', right: '0.25rem', color:"#fff", background: "#000" }}>
                                <EditIcon />
                                </IconButton>
                            </label>
                            </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="name"
                                label="Name Agent"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="title"
                                    label="Title Broker | Realtor"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ marginBottom: '8px' }}
                                    value={this.state.title}
                                    onChange={this.handleTitle}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="password"
                                label="Password"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                margin="normal"
                                type="password"
                            />
                            </Grid>





                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth size="small" style={{ marginBottom: '8px' }}>
                                <InputLabel id="genero-label" style={{ backgroundColor: '#fff',padding: '0 8px' }}>Género</InputLabel>
                                <Select labelId="genero-label" value={this.state.role} onChange={this.handleRoleChange}>
                                    <MenuItem value="0">User</MenuItem>
                                    <MenuItem value="1">Administrator</MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth size="small" style={{ marginBottom: '8px' }}>
                            <InputLabel id="demo-simple-select-outlined-labe2l" style={{ backgroundColor: '#fff',padding: '0 8px' }}>Active</InputLabel>
                                <Select labelId="demo-simple-select-outlined-label2" value={this.state.active} onChange={this.handleRoleActive}>
                                    <MenuItem value="0">No</MenuItem>
                                    <MenuItem value="1">Yes</MenuItem>
                                </Select>
                            </FormControl>

                            </Grid>


                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="facebook"
                                label="Facebook Url"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.facebook}
                                onChange={this.handleFacebookChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="twitter"
                                label="Twitter Url"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.twitter}
                                onChange={this.handleTwitterChange}
                                margin="normal"
                            />
                            </Grid>

                            
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="instagram"
                                label="Instagram Url"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.instagram}
                                onChange={this.handleInstagramChange}
                                margin="normal"
                            />
                            </Grid>

                                                        
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="linkedin"
                                label="Linkedin Url"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.linkedin}
                                onChange={this.handleLinkedinChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="youtube"
                                label="Youtube Url"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.youtube}
                                onChange={this.handleYoutubeChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="website"
                                label="Personal Website"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.website}
                                onChange={this.handleWebsiteChange}
                                margin="normal"
                            />
                            </Grid>
                            <Grid item xs={12} container style={{justifyContent: 'flex-end'}}>
                                <Button type="submit" variant="contained" style={{margin: '8px',backgroundColor: '#c95c3a'}}>
                                    Register
                                </Button>
                                <Button type="button" onClick={this.cancelReg} variant="contained"  style={{margin: '8px', backgroundColor: '#808080'}}>
                                    Cancel
                                </Button>
                            </Grid>

                        </Grid>
                        <Box height="50px" />
                            </form>
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default CreateUser;