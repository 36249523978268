
import * as React from 'react';

class Logout extends React.Component <{}, {}> {
    constructor(props: any) {
        super(props);
        localStorage.setItem("user","{}");
        window.location.assign(process.env.PUBLIC_URL);
    }

    public render() {
        return (
            <div></div>
        );
    }
}

export default Logout;
