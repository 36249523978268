import * as React from 'react';
import './adminvendors.css';
import darkLogo from './../../assets/hubLogo.png'
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Avatar, IconButton, Box } from '@mui/material';
import AdminService from '../../services/Admin.service';
import EditIcon from '@mui/icons-material/Edit';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    id: string;
    name: string;
    company: string;
    service: string;
    phone: string;
    email: string;
    status: number;
    website: string;
    rows: any;
}
  
class ModifyVendor extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);
        
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.handleWebsiteChange = this.handleWebsiteChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.cancelReg = this.cancelReg.bind(this);
        this.state = {
            id:  localStorage.getItem("vendorid") || "",
            name: '',
            company: '',
            service: '',
            phone: '',
            email: '',
            status: 1,
            website: '',
            rows: null,
        };
    }

    public handleNameChange = (event:any) => this.setState({name:event.target.value});
    public handleEmailChange = (event:any) => this.setState({email:event.target.value});
    public handlePhoneChange = (event:any) => this.setState({phone:event.target.value});
    public handleStatusChange = (event:any) => this.setState({status:event.target.value});
    public handleCompanyChange = (event:any) => this.setState({company:event.target.value});
    public handleServiceChange = (event:any) => this.setState({service:event.target.value});
    public handleWebsiteChange = (event:any) => this.setState({website:event.target.value});
            

    public handleSubmit = (event:any) => {
        event.preventDefault();

            const data = {
                id: this.state.id,
                name: this.state.name,
                email: this.state.email,
                status: this.state.status,
                company: this.state.company,
                service: this.state.service,
                phone: this.state.phone,
                website: this.state.website,
            };

            this.adminService.modifyVendor(data).then(
                (response: any) => {
                    window.location.assign(process.env.PUBLIC_URL+"/adminvendors");
                });

    }

    public  cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL+"/adminvendors");
    };


    componentDidMount(){
        this.adminService.getCategories().then(
            (response) => {
                this.setState({rows:response});
            });
        this.adminService.getVendor({id : this.state.id}).then(
            (response) => {
                this.setState({ name: response.name,
                                email: response.email,
                                status: response.status,
                                company: response.company,
                                service: response.service,
                                phone: response.phone,
                                website: response.website,
                                });
            });
        
    }

    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '50px', paddingTop:'20px' }}>
                        <h4>Modify User</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                      </div>
                      <br/><br/>
                        <form onSubmit={this.handleSubmit}>

                        <Grid container spacing={2}>

                           
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="name"
                                label="Name Vendor"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="company"
                                    label="Company Name"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ marginBottom: '8px' }}
                                    value={this.state.company}
                                    onChange={this.handleCompanyChange}
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>

                            <FormControl variant="outlined" fullWidth size="small" style={{ marginBottom: '8px' }}>
                            <InputLabel id="demo-simple-select-outlined-labe2l" style={{ backgroundColor: '#fff',padding: '0 8px' }}>Service</InputLabel>
                                <Select labelId="demo-simple-select-outlined-label2" value={this.state.service} onChange={this.handleServiceChange}>
                                {this.state.rows && this.state.rows.map((category: any, index: any) => (
                                    <MenuItem key={index} value={category.name}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth size="small" style={{ marginBottom: '8px' }}>
                            <InputLabel id="demo-simple-select-outlined-labe2l" style={{ backgroundColor: '#fff',padding: '0 8px' }}>Active</InputLabel>
                                <Select labelId="demo-simple-select-outlined-label2" value={this.state.status} onChange={this.handleStatusChange}>
                                    <MenuItem value="0">No</MenuItem>
                                    <MenuItem value="1">Yes</MenuItem>
                                </Select>
                            </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="phone"
                                label="Phone"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.phone}
                                onChange={this.handlePhoneChange}
                                margin="normal"
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                margin="normal"
                            />
                            </Grid>


                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="website"
                                label="Website"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.website}
                                onChange={this.handleWebsiteChange}
                                margin="normal"
                            />
                            </Grid>

                            

                            <Grid item xs={12} container style={{justifyContent: 'flex-end'}}>
                                <Button type="submit" variant="contained"  style={{margin: '8px',backgroundColor: '#c95c3a'}}>
                                    Modify
                                </Button>
                                <Button type="button" onClick={this.cancelReg} variant="contained"  style={{margin: '8px', backgroundColor: '#808080'}}>
                                    Cancel
                                </Button>
                            </Grid>

                        </Grid>

                        
                        <Box height="50px" />
                           
                            </form>
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default ModifyVendor;