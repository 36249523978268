import * as React from 'react';
import './adminvendors.css';

import darkLogo from './../../assets/hubLogo.png'

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Avatar, Badge, Button } from '@mui/material';

import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    rows: GridRowsProp;
    selectionModel:any;
}
  
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50 },
    { field: 'name', headerName: 'Name', width: 400 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 250,
        renderCell: (params) => {
          const handleButtonClick = () => {
            localStorage.setItem("catid", ""+params.id);
            window.location.assign(process.env.PUBLIC_URL+"/delcat");
          };
          return <div className="divButton">
                <Button variant="outlined" onClick={handleButtonClick} style={{margin: '8px'}}>Delete</Button>
                </div>;
        },
      },
  ];

class AdminCategories extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    
    constructor(props: any) {
        super(props);
        this.createUser = this.createUser.bind(this);

        this.state = {
            rows: [],
            selectionModel: []
        };
    }

    public  createUser = () => {
        window.location.assign(process.env.PUBLIC_URL+"/createuser");
    };

    public announces = () =>{
      window.location.assign(process.env.PUBLIC_URL+"/announces");
    }

    componentDidMount(){
        this.adminService.getCategories().then(
            (response) => {
                this.setState({rows:response});
            });
    }

    public render() {

      const NewhandleButtonClick = () => {
        window.location.assign(process.env.PUBLIC_URL+"/createcategory");
      };

        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '30px', paddingTop:'20px' }}>
                        <h4>Admin Panel</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/admincategories">ALL CATEGORIES</a>
                      </div>
                      <br/><br/>
                      <div className="divButton">
                    <Button variant="outlined" onClick={NewhandleButtonClick} style={{margin: '8px'}}>New Category</Button>
                    </div>  <br/><br/>
                        <DataGrid
                            rows={this.state.rows}
                            columns={columns}
                            />
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default AdminCategories;