import * as React from 'react';
import './Announces.css';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Checkbox, FormControlLabel } from '@mui/material';
import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    password: string;
    id: string;
    name:string;
    name2:string;
    name3:string;
    isActive: boolean;
    isActive2: boolean;
    isActive3: boolean;
}
  
class Announces extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);
        
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelReg = this.cancelReg.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);

        this.state = {
            password: '',
            name: '',
            name2: '',
            name3: '',
            id:  localStorage.getItem("myId") || "",
            isActive: false,
            isActive2: false,
            isActive3: false,
        };
    }
    public handleNameChange = (event:any) => this.setState({name:event.target.value});
    public handlePasswordChange = (event:any) => this.setState({name:event.target.value});
    public handlePasswordChange2 = (event:any) => this.setState({name2:event.target.value});
    public handlePasswordChange3 = (event:any) => this.setState({name3:event.target.value});
    public handleCheckboxChange = (event:any) => this.setState({isActive: event.target.checked });
    public handleCheckboxChange2 = (event:any) => this.setState({isActive2: event.target.checked });
    public handleCheckboxChange3 = (event:any) => this.setState({isActive3: event.target.checked });
    public handleSubmit = (event:any) => {
        event.preventDefault();

            const data = {
                name: this.state.name,
                name2: this.state.name2,
                name3: this.state.name3,
                status: this.state.isActive ? 1 : 0,
                status2: this.state.isActive2 ? 1 : 0,
                status3: this.state.isActive3 ? 1 : 0,
            };

            this.adminService.changeAnnounce(data).then(
                (response: any) => {
                    window.location.assign(process.env.PUBLIC_URL+"/adminpanel");
                });

    }

    public  cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL+"/adminpanel");
    };

    componentDidMount(){
        this.adminService.getAnnounces().then(
            (response) => {
                this.setState({ name:response.name, name2:response.name2, name3:response.name3,
                    isActive:response.status === "0" ? false: true,
                     isActive2:response.status2 === "0" ? false: true, isActive3: response.status3 === "0" ? false: true,
                                });
            });
    }

    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '50px', paddingTop:'20px' }}>
                        <h4>Modify Announce</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/admincategories">ALL CATEGORIES</a>
                      </div>
                      <br/><br/>
                        <form onSubmit={this.handleSubmit}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                            <TextField
                                label="Announce 1"
                                variant="outlined"
                                value={this.state.name}
                                onChange={this.handlePasswordChange}
                                fullWidth
                                margin="normal"
                                aria-readonly={false}
                            />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.isActive}
                                            onChange={this.handleCheckboxChange}
                                        />
                                    }
                                    label="Active"
                                    className="checkbox-label"
                                    />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                            <TextField
                                label="Announce 2"
                                variant="outlined"
                                value={this.state.name2}
                                onChange={this.handlePasswordChange2}
                                fullWidth
                                margin="normal"
                                aria-readonly={false}
                            />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.isActive2}
                                            onChange={this.handleCheckboxChange2}
                                        />
                                    }
                                    label="Active"
                                    className="checkbox-label"
                                    />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                            <TextField
                                label="Announce 3"
                                variant="outlined"
                                value={this.state.name3}
                                onChange={this.handlePasswordChange3}
                                fullWidth
                                margin="normal"
                                aria-readonly={false}
                            />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.isActive3}
                                            onChange={this.handleCheckboxChange3}
                                        />
                                    }
                                    label="Active"
                                    className="checkbox-label"
                                    />
                            </Grid>
                            <Grid item xs={12} container style={{justifyContent: 'flex-end'}}>
                            <Button type="submit" variant="contained"  style={{margin: '8px',backgroundColor: '#c95c3a'}}>
                                Modify
                            </Button>
                            <Button type="button" onClick={this.cancelReg} variant="contained" style={{margin: '8px',backgroundColor: '#808080'}}>
                                Cancel
                            </Button>
                            </Grid>
                            </Grid>
                            </form>
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default Announces;