import React, { useState } from 'react';
import './MenuPrincipal.css'; // Archivo de estilos CSS para el popup
import darkLogo from './../../assets/hubLogo.png'
import AdminService from '../../services/Admin.service';
import MenuIcon  from '@mui/icons-material/Menu';


interface annon{
  feccreate: string;
  id: string;
  message: string;
  status: string;
}
const MenuPrincipal: React.FC = () => {

  const [name, setName] = React.useState<annon[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const adminService = new AdminService();
  const [currentPhraseIndex, setCurrentPhraseIndex] = React.useState(0);

  const isAuthenticatedBr = localStorage.getItem("user") || '';
  let isAuthenticated:any;

  if(isAuthenticatedBr !== ''){
      const AdminRest = JSON.parse(localStorage.getItem("user") || '');
      isAuthenticated = AdminRest;
  }

  React.useEffect(() => {

  adminService.getAnnounce().then(
    (response: any) => {
        setName(response);        
    });
  
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) =>
        prevIndex === name.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);

  }, [name.length]);
    
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
    <div className="menuContainer">
        <div className="menurthirt">
            &nbsp;
        </div>
        
        <div className="menuFirst">
            <h4><img alt="img" src={darkLogo} className="logo-dark" /></h4>
            
            <div className="menu-container">
                <a className="menu-item" href="/home">HOME</a>
                <span className="menu-separator"><strong>|</strong></span>
                <a className="menu-item" href="/profile">PROFILE</a>
                <span className="menu-separator"><strong>|</strong></span>
                <a className="menu-item" href="/changepassword">CHANGE PASSWORD</a>
                <span className="menu-separator"><strong>|</strong></span>
                <a className="menu-item" href="/logout">LOGOUT</a>
            </div>
            
        </div>
        <div className="menuSecond">
          <img className="avatars-menu" src={process.env.REACT_APP_API_PATH+"/uploads/"+isAuthenticated.avatar} alt={isAuthenticated.name} />
                <br /> 
              {isAuthenticated.name} <br />
              (<a href="/logout">Logout</a>)
              
          </div>
            <div className="menurthirtMobile">
            <MenuIcon onClick={toggleMenu} /> 
          </div>
      </div>
      {menuOpen && (<div className=".mobile-menu-open">
                        <div className="menu2">
                            <div className="menu-item2">
                              <span className="start-icon">&#124;</span> HOME
                            </div>
                        </div> 
                        <div className="menu2">
                            <div className="menu-item2">
                              <span className="start-icon">&#124;</span> PROFILE
                            </div>
                        </div> 
                        <div className="menu2">
                            <div className="menu-item2">
                              <span className="start-icon">&#124;</span> CHANGE PASSWORD
                            </div>
                        </div> 
                        <div className="menu2">
                            <div className="menu-item2">
                              <span className="start-icon">&#124;</span> LOGOUT
                            </div>
                        </div> 
                      </div> 
                      )}
      <div className="announces">
        {name && name[currentPhraseIndex] && name[currentPhraseIndex].message}
      </div>
    </>
  );
};

export default MenuPrincipal;