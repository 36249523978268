import * as React from 'react';
import './AllSignature.css';

import darkLogo from './../../assets/hubLogo.png'

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Avatar, Badge, Button, Card, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, styled } from '@mui/material';
import Twitter from '@mui/icons-material/Twitter';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import Youtube from '@mui/icons-material/YouTube';
import Website from '@mui/icons-material/Language';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Cake';

import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    brokers: GridRowsProp;
    selectionModel:any;
    user: any;
    sign: any;
    eCard: any;
    selectedBroker: any;
}

const ColoredIcon = styled('span')(({ color }) => ({
  color: color,
}));
  
class AllSignature extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();

    constructor(props: any) {
        super(props);
        this.createUser = this.createUser.bind(this);
        this.handleBrokerChange = this.handleBrokerChange.bind(this);

        this.state = {
            brokers: [],
            selectionModel: [],
            user: '',
            sign: '',
            eCard: '',
            selectedBroker: '',
        };
    }

    public  createUser = () => {
        window.location.assign(process.env.PUBLIC_URL+"/createuser");
    };

    public getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) return JSON.parse(userStr);
    
        return null;
      }

    componentDidMount(){
        this.adminService.getBrokers().then(
            (response) => {
                this.setState({brokers:response});
            });
    }

      public  handleBrokerChange = (event: any) => {
        const selectedBrokerId = event.target.value as number;
        const selectedBroker = this.state.brokers.find(broker => broker.id === selectedBrokerId) || null;
        console.log(selectedBroker);
        console.log(selectedBrokerId);
        this.setState({ selectedBroker });
      };

    public render() {

        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '30px', paddingTop:'20px' }}>
                        <h4>All Signature | Cards</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/admincategories">ALL CATEGORIES</a>
                      </div>
                      <br/><br/>
                        <div className="roots">
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>

                            <Grid item xs={12}>
                                <FormControl>
                                    <InputLabel>Select Agent</InputLabel>
                                    <Select value={this.state.selectedBroker?.id || ''} onChange={this.handleBrokerChange}
                                    style={{width:'500px'}}>
                                        {this.state.brokers.map(broker => (
                                        <MenuItem key={broker.id} value={broker.id}>
                                            {broker.name}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                            </Grid>
                                {this.state.selectedBroker && 
                                (
                                <><Grid item xs={12}>
                                        <h4>Full Version</h4>
                                    </Grid><Grid item xs={12}>
                                            <iframe src={"https://mainstreetlistings.co/eSignatures/" + this.state.selectedBroker.sign} width="100%" height="400" title="ver1"></iframe>
                                        </Grid><Grid item xs={12}>
                                            <h4>Short Version</h4>
                                        </Grid><Grid item xs={12}>
                                            <iframe src={"https://mainstreetlistings.co/eSignatures/v2/generate_sign.php?id=" + this.state.selectedBroker.id} width="100%" height="400" title="ver2"></iframe>
                                        </Grid><Grid item xs={12}>
                                            <h4>e-Card</h4>
                                            <span><a href={this.state.selectedBroker.cardurl}  style={{fontSize: '12px'}}>{this.state.selectedBroker.cardurl.replace('https://','')}</a></span>
                                        </Grid><Grid item xs={12}>
                                            <iframe src={this.state.selectedBroker.cardurl} width="100%" height="400" title="ver2"></iframe>
                                        </Grid><Grid item xs={12}>
                                            <h4><br></br></h4>
                                        </Grid></>
                            )}
                        </Grid>
                        </div>
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default AllSignature;
