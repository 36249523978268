import * as React from 'react';
import './adminvendors.css';
import darkLogo from './../../assets/hubLogo.png'
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Avatar, IconButton, Box } from '@mui/material';
import AdminService from '../../services/Admin.service';
import EditIcon from '@mui/icons-material/Edit';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    id: string;
}
  
class DelCat extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();
    constructor(props: any) {
        super(props);
        
        this.state = {
            id:  localStorage.getItem("catid") || "",
        };
    }

    componentDidMount(){
        this.adminService.delCat({id : this.state.id}).then(
            (response) => {
                window.location.assign(process.env.PUBLIC_URL+"/admincategories");
            });
    }

    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '50px', paddingTop:'20px' }}>
                        <h4>Delete Category</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                      </div>
                      <br/><br/>
                        <Grid container spacing={2}>

                    

                        </Grid>

                        
                        <Box height="50px" />
                           
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default DelCat;