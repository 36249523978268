import * as React from 'react';
import './login.css';
import logo from './../../assets/LogoWhite.png'

import AuthService from "./../../services/Auth.service";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

interface IStateLoginForm {
    user: string;
    pwd: string;
    message: string;
    message2: string;
    loading: boolean;
    fpwd: boolean;
    userfp: string;
}

class Login extends React.Component<{}, IStateLoginForm> {
    public state: IStateLoginForm;
    authService = new AuthService();
    constructor(props: any) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLoginFP = this.handleLoginFP.bind(this);
        this.state = {
            user: '',
            pwd: '',
            message: '',
            message2: '',
            loading: false,
            fpwd: false,
            userfp: '',
        };
    }

    validationSchema() {
        return Yup.object().shape({
            user: Yup.string().required("This field is required!"),
            pwd: Yup.string().required("This field is required!"),
        });
    }

    validationSchemaFP() {
        return Yup.object().shape({
            userfp: Yup.string().required("This field is required!"),
        });
    }

    initialValues = {
        user: "",
        pwd: "",
    };

    initialValuesFP = {
        userfp: "",
    };

    handleLogin(formValue: { user: string; pwd: string }) {
        const { user, pwd } = formValue;

        this.setState({
            message: "",
            loading: true
        });

        this.authService.login(user, pwd).then(
            (response) => {
                if (response.code === 200) {
                    window.location.assign(process.env.PUBLIC_URL + "/home");
                } else if (response.code === 401) {
                    this.setState({
                        loading: false,
                        message: "* User doesn't exists, try again."
                    });
                } else if (response.code === 402) {
                    this.setState({
                        loading: false,
                        message: "* Password incorrect, try again."
                    });
                }
            }
        );
    }

    handleLoginFP(formValue: { userfp: string}) {
        const { userfp } = formValue;

        this.setState({
            message2: "",
            loading: true
        });

        this.authService.forgotpwd(userfp).then(
            (response:any) => {
                if (response.code === 200) {
                    this.setState({
                        loading: false,
                        message2: "We sent your password to your email."
                    });
                } else if (response.code === 401) {
                    this.setState({
                        loading: false,
                        message2: "* User doesn't exists, try again."
                    });
                } 
            }
        );
    }

    public TogglePwd() {
        this.setState({ fpwd: !this.state.fpwd });
    }

    public render() {
        return (
            <div className='login'>
                <div>
                    <img src={logo} alt="img11" className="Logo" />
                </div>

                {!this.state.fpwd ? (
                    <>
                        <Formik
                            initialValues={this.initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.handleLogin}
                        >
                            <Form className="react-form">
                                {this.state.message !== '' && (
                                    <div className="form-group">
                                        <div className="alert-danger" role="alert">
                                            {this.state.message}
                                        </div>
                                    </div>
                                )}
                                <div className="form-group">
                                    <label>Username:</label>
                                    <Field type="text" id="user" className="form-input" name="user" />
                                    <ErrorMessage
                                        name="user"
                                        component="div"
                                        className="alert-danger" />
                                </div><div className="form-group">
                                    <label>Password:</label>
                                    <Field type="password" id="pwd" className="form-input" name="pwd" />
                                    <ErrorMessage
                                        name="pwd"
                                        component="div"
                                        className="alert-danger" />
                                </div><button type="submit" id="formButton" className="btn btn-primary"
                                >Login</button><div className="form-group space-back">
                                    <div className="alert-danger" role="alert">
                                        <a onClick={() => this.TogglePwd()}>
                                            Forgot Password?
                                        </a>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </>
                )
                    : (
                        <>
                            <Formik
                                initialValues={this.initialValuesFP}
                                validationSchema={this.validationSchemaFP}
                                onSubmit={this.handleLoginFP}
                            >
                                <Form className="react-form">
                                    {this.state.message2 !== '' && (
                                        <div className="form-group">
                                            <div className="alert-danger" role="alert">
                                                {this.state.message2}
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <label>Username:</label>
                                        <Field type="text" id="userfp" className="form-input" name="userfp" />
                                        <ErrorMessage
                                            name="userfp"
                                            component="div"
                                            className="alert-danger" />
                                    </div>

                                    <button type="submit" id="formButton" className="btn btn-primary"
                                    >Get New Password</button>

                                    <div className="form-group space-back">
                                        <div className="alert-danger" role="alert">
                                            <a onClick={() => this.TogglePwd()}>
                                                Log in
                                            </a>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                        </>
                    )}


                <div className="footer">
                    LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default Login;