import axios from "axios";

class AdminService {

  protected instance: any;
  private baseUrl = '';

  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH || '';
    this.instance = axios.create({
      baseURL: this.baseUrl,
    });
  }

  public getUsers() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allu.php")
      .then(response => {
        return response.data;
      });
  }

  public getBrokers() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allb.php")
      .then(response => {
        return response.data;
      });
  }

  public getVendors() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allv.php")
      .then(response => {
        return response.data;
      });
  }

  public getVendorsVd() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allvd.php")
      .then(response => {
        return response.data;
      });
  }

  public getCategories() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allcat.php")
      .then(response => {
        return response.data;
      });
  }

  public getProperties() : Promise<any> {
    return axios
      .get(this.baseUrl + "/allproperties.php")
      .then(response => {
        return response.data;
      });
  }

  public getUser(data : any) : Promise<any> {
    return axios
    .post(this.baseUrl + "/getUser.php",data)
    .then(response => {
      return response.data;
    });
  }

  public getVendor(data : any) : Promise<any> {
    return axios
    .post(this.baseUrl + "/getVendor.php",data)
    .then(response => {
      return response.data;
    });
  }

  public getAnnounce(): Promise<any>{
    return axios
    .get(this.baseUrl + "/getAnnounce.php")
    .then(response => {
      return response.data;
    });
  }

  
  public getAnnounces(): Promise<any>{
    return axios
    .get(this.baseUrl + "/getAnnounces.php")
    .then(response => {
      return response.data;
    });
  }

  public createUser(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/createUser.php",data)
      .then(response => {
        return response.data;
      });
  }

  public createVendor(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/createVendor.php",data)
      .then(response => {
        return response.data;
      });
  }

  public createCat(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/createcat.php",data)
      .then(response => {
        return response.data;
      });
  }

  public modifyUser(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/modifyUser.php",data)
      .then(response => {
        return response.data;
      });
  }

  public delCat(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/delcat.php",data)
      .then(response => {
        return response.data;
      });
  }

  public modifyVendor(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/modifyVendor.php",data)
      .then(response => {
        return response.data;
      });
  }

  public changepwd(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/changePwd.php",data)
      .then(response => {
        return response.data;
      });
  }

  public changeAnnounce(data : any) : Promise<any> {
    return axios
      .post(this.baseUrl + "/changeAnnounce.php",data)
      .then(response => {
        return response.data;
      });
  }
  

}

export default AdminService;