import * as React from 'react';
import './home.css';

import image_1 from './../../assets/1-Skylope.jpg'
import image_2 from './../../assets/2-BoomTown.jpg'
import image_3 from './../../assets/3-Social-Media-Content.jpg'
import image_4 from './../../assets/4-Market-Reports.jpg'
import image_5 from './../../assets/5-Luxe-Roster.jpg'
import image_6 from './../../assets/8-esignature.jpg'
import image_7 from './../../assets/9-Training.jpg'
import image_8 from './../../assets/blank.jpg'
import image_9 from './../../assets/10_Forbes_portal.jpg'
import image_11 from './../../assets/11-Logos-Dash.jpg'
import image_12 from './../../assets/7-resources.jpg'
import Popup from '../Popup/Popup';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';


interface IStateLoginForm {
    buttonAttr: any;
    error: string;
    isLogin: boolean;
}

const isAuthenticatedBr = localStorage.getItem("user") || '';
let isAuthenticated:any;
if(isAuthenticatedBr !== ''){
    const AdminRest = JSON.parse(localStorage.getItem("user") || '');
    isAuthenticated = AdminRest;
}


class Home extends React.Component <{}, IStateLoginForm> {

    public render() {
        return (
            <div className="home">
               <section id="neighborhood_heading" className="cy-custom">
               <MenuPrincipal/>  
                <div id="neighborhoods">
                    
                    <a className="fade-up" target="_blank" href="https://app.skyslope.com/AgentHome.aspx">
                    <span className="inner ">
                        <span className="title">Skyslope</span><br />
                        <span className="subtitle">(Transaction Management System)</span>                        
                    </span>
                    <img alt="img2" className="fade-in logoHome" src={image_1} data-photosource={image_1} />
                    </a>
                    <a className="fade-up" target="_blank" href="https://leads.boomtownroi.com/login/">
                    <span className="inner">
                        <span className="title">BoomTown</span><br />
                        <span className="subtitle">(Client Management System)</span>                                
                    </span>
                    <img alt="img3" className="fade-in logoHome" src={image_2} data-photosource={image_2} /> 
                    </a>
                    <a className="fade-up" target="_blank" href="https://www.forbesglobalproperties.com/dashboard">
                    <span className="inner">
                        <span className="title">Forbes Member Portal</span>
                    </span>
                    <img alt="img4" className="fade-in logoHome" src={image_9} data-photosource={image_9} /> 
                    </a>
                    <a className="fade-up" href={process.env.PUBLIC_URL+"/brokers"}>
                    <span className="inner">
                        <span className="title">Broker Roster</span>
                    </span>
                    <img alt="img6" className="fade-in logoHome" src={image_5} data-photosource={image_5} />
                    </a>
                    <a className="fade-up"  target="_blank" href="https://drive.google.com/drive/folders/1UC9njo9fCa7zonPA5iyGtpvnQ6Lt4Hk2?usp=sharing">
                    <span className="inner">
                        <span className="title">Social Media Content</span>
                    </span>
                    <img alt="img4" className="fade-in logoHome" src={image_3} data-photosource={image_3} /> 
                    </a>
                    <a className="fade-up" target="_blank" href="https://altos.re/r/1c921312-ab6e-4d30-b6c5-5f4491591449">
                    <span className="inner">
                        <span className="title">Market Reports</span>
                    </span>
                    <img alt="img5" className="fade-in logoHome" src={image_4} data-photosource={image_4} />
                    </a>
                    
                    <a className="fade-up" href={process.env.PUBLIC_URL+"/eSignature"}>
                    <span className="inner">
                        <span className="title">e-Signatures</span>
                    </span>
                    <img alt="img7" className="fade-in logoHome" src={image_6} data-photosource={image_6} />
                    </a>

                    <a className="fade-up" target="_blank" href={"https://drive.google.com/drive/folders/1XDXMpHe_xcvIvnFMJYpto62YJu4FCtzG?usp=sharing"}>
                    <span className="inner">
                        <span className="title">LOGOS</span>
                    </span>
                    <img alt="img7" className="fade-in logoHome" src={image_11} data-photosource={image_11} />
                    </a>

                    <a className="fade-up" href={process.env.PUBLIC_URL+"/resources"}>
                    <span className="inner">
                        <span className="title">Resources</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_12} data-photosource={image_12} />
                    </a>
                    <a className="fade-up" href={process.env.PUBLIC_URL+"/vendors"}>
                    <span className="inner">
                        <span className="title">Vendors</span>
                    </span>
                    <img alt="img9" className="fade-in logoHome" src={image_8} data-photosource={image_8} />
                    </a>
                    <a className="fade-up"  href="#">
                     <span className="inner">
                        <span className="title">Training</span>
                    </span>
                    <img alt="img10" className="fade-in logoHome" src={image_7} data-photosource={image_7} />
                    </a>
                    {isAuthenticated.isAdmin === "1" && (<a className="fade-up" href={process.env.PUBLIC_URL+"/adminpanel"}>
                    <span className="inner">
                        <span className="title">Admin Panel</span>
                    </span>
                    <img alt="img8" className="fade-in logoHome" src={image_8} data-photosource={image_8} />
                    </a>)}

                </div>
                </section>
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default Home;