import axios from "axios";

class AuthService {

  protected instance: any;
  private baseUrl = '';

  constructor() {
    this.baseUrl = process.env.REACT_APP_API_PATH || '';
    this.instance = axios.create({
      baseURL: this.baseUrl,
    });
  }

  public login(user: string, pwd: string) : Promise<any> {
    return axios
      .post(this.baseUrl + "/auth.php", {
        user,
        pwd
      })
      .then(response => {
        if (response.data.code !== 401 || response.data.code !== 402) {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("myId", response.data.id);
        }
        return response.data;
      });
  }

  public forgotpwd(userfp: string) : Promise<any> {
    return axios
      .post(this.baseUrl + "/forgotpwd.php", {
        userfp,
      })
      .then(response => {
        return response.data;
      });
  }
  

  public logout() {
    localStorage.removeItem("user");
  }

  public getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default AuthService;