import * as React from 'react';
import './adminvendors.css';
import darkLogo from './../../assets/hubLogo.png'

import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import AdminService from '../../services/Admin.service';
import MenuPrincipal from '../MenuPrincipal/MenuPrincipal';

interface IStateAdminPanelForm {
    name: string;
}
  
class CreateCategory extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();

    constructor(props: any) {
        super(props);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelReg = this.cancelReg.bind(this);
        this.state = {
            name: '',
        };
    }

    public handleNameChange = (event:any) => this.setState({name:event.target.value});
    public handleSubmit = (event:any) => {
        event.preventDefault();

                const data = {
                    name: this.state.name,
                };
            
            this.adminService.createCat(data).then(
                (response: any) => {
                    window.location.assign(process.env.PUBLIC_URL+"/admincategories");
                });

    }

    public  cancelReg = () => {
        window.location.assign(process.env.PUBLIC_URL+"/admincategories");
    };


    public render() {
        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                <MenuPrincipal />  
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '50px', paddingTop:'20px' }}>
                        <h4>Create New Category</h4>
                        <br/>
                        <div className="menu-container">
                          <a className="menu-item" href="/adminpanel">ADMIN PANEL</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/createuser">NEW USER</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/announces">ANNOUNCES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/alleSignature">ALL SIGNATURES</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/adminvendors">ALL VENDORS</a>
                          <span className="menu-separator"><strong>|</strong></span>
                          <a className="menu-item" href="/admincategories">ALL CATEGORIES</a>
                      </div>
                      <br/><br/>
                        <form onSubmit={this.handleSubmit}>

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>
                            <TextField
                                id="name"
                                label="Name Category"
                                variant="outlined"
                                fullWidth
                                size="small"
                                style={{ marginBottom: '8px' }}
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                margin="normal"
                            />
                            </Grid>

                            

                            <Grid item xs={12} container style={{justifyContent: 'flex-end'}}>
                                <Button type="submit" variant="contained" style={{margin: '8px',backgroundColor: '#c95c3a'}}>
                                    Register
                                </Button>
                                <Button type="button" onClick={this.cancelReg} variant="contained"  style={{margin: '8px', backgroundColor: '#808080'}}>
                                    Cancel
                                </Button>
                            </Grid>

                        </Grid>
                        <Box height="50px" />
                            </form>
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default CreateCategory;