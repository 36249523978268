import * as React from 'react';
import './Signature.css';

import darkLogo from './../../assets/hubLogo.png'

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Avatar, Badge, Button, Card, CardContent, CardHeader, Grid, IconButton, Tooltip, styled } from '@mui/material';
import Twitter from '@mui/icons-material/Twitter';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import Youtube from '@mui/icons-material/YouTube';
import Website from '@mui/icons-material/Language';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Cake';

import AdminService from '../../services/Admin.service';
import { FileCopy } from '@mui/icons-material';

interface IStateAdminPanelForm {
    rows: GridRowsProp;
    selectionModel:any;
    user: any;
    sign: any;
    eCard: any;
    isCopied: boolean;
}

const ColoredIcon = styled('span')(({ color }) => ({
  color: color,
}));
  
class Signature extends React.Component <{}, IStateAdminPanelForm> {
    adminService = new AdminService();

    constructor(props: any) {
        super(props);
        this.createUser = this.createUser.bind(this);

        this.state = {
            rows: [],
            selectionModel: [],
            user: '',
            sign: '',
            eCard: '',
            isCopied: false,
        };
    }

    public  createUser = () => {
        window.location.assign(process.env.PUBLIC_URL+"/createuser");
    };

    public getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) return JSON.parse(userStr);
    
        return null;
      }

    componentDidMount(){
        const user = this.getCurrentUser();
        console.log(user);
        this.setState({user: user.id, sign: user.sign, eCard: user.cardurl});
    }

    public handleCopyClick = (texto: string) => {
        const textToCopy = texto;
        navigator.clipboard.writeText(textToCopy)
          .then(() => {
            this.setState({ isCopied: true});
            setTimeout(() => {
                this.setState({ isCopied: false});
            }, 1500);
          })
          .catch((error) => {
            console.error('Error al copiar el texto: ', error);
          });
      };

    public render() {

        return (
            <div className="adminpanel">
                <section id="neighborhood_heading" className="cy-custom">
                    <h4>
                    <a href={process.env.PUBLIC_URL+"/home"}>
                      <img alt="img" src={darkLogo} className="logo-dark"/>
                    </a>
                    </h4>
                    <div style={{ height: 500, width: '100%', paddingLeft: '50px', 
                    paddingRight: '50px', paddingBottom: '30px', paddingTop:'20px' }}>
                        <h4>My e-Signature</h4>
                        <br/>
                        <div className="roots">
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <h4>Full Version</h4>
                            </Grid>
                            <Grid item xs={12}>
                            <iframe src={"https://mainstreetlistings.co/eSignatures/"+this.state.sign} width="100%" height="400" title="ver1"></iframe>
                            </Grid>
                            <Grid item xs={12}>
                                <h4>Short Version</h4>
                            </Grid>
                            <Grid item xs={12}>
                            <iframe src={"https://mainstreetlistings.co/eSignatures/v2/generate_sign.php?id="+this.state.user} width="100%" height="400" title="ver2"></iframe>
                            </Grid>
                            <Grid item xs={12}>
                                <h4>e-Card</h4>
                                <span><a href={this.state.eCard} style={{fontSize: '12px'}}>{this.state.eCard.replace('https://','')}</a></span>
                                <Tooltip title={this.state.isCopied ? 'Copied' : 'Copy'}>
                                    <IconButton onClick={() =>this.handleCopyClick(this.state.eCard)} color={this.state.isCopied ? 'primary' : 'default'}>
                                    <FileCopy />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                            <iframe src={this.state.eCard} width="100%" height="400" title="ver2"></iframe>
                            </Grid>
                            <Grid item xs={12}>
                                <h4><br></br></h4>
                            </Grid>
                        </Grid>
                            
                        </div>
                    </div>
                </section> 
                <div className="footer-home">
                LUXE Forbes Global Properties
                </div>
            </div>
        );
    }
}

export default Signature;
