import { createRoot } from 'react-dom/client'

import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from './components/Home/home';
import Login from './components/Login/login';
import Logout from './components/Logout/logout';
import Resources from './components/Resources/resources';
import AdminPanel from './components/AdminPanel/adminpanel';
import CreateUser from './components/AdminPanel/createuser';
import ModifyUser from './components/AdminPanel/modifyuser';
import ChangePwd from './components/AdminPanel/changepwd';
import Brokers from './components/Brokers/brokers';
import Profile from './components/UserPanel/modifyuser';
import ChangePwdUser from './components/UserPanel/changepwd';
import RegisterUser from './components/RegisterUser/RegisterUser';
import Maps from './components/Maps/maps';
import Signature from './components/Signature/Signature';
import AllSignature from './components/AllSignature/AllSignature';
import Announces from './components/Announces/Announces';
import ResourceAdmin from './components/AdminPanel/resourcesadmin';
import Vendors from './components/Vendors/vendors';
import AdminVendors from './components/AdminVendors/adminvendors';
import CreateVendors from './components/AdminVendors/createvendor';
import ModifyVendor from './components/AdminVendors/modifyvendor';
import AdminCategories from './components/AdminVendors/admincategories';
import DelCat from './components/AdminVendors/delcat';
import CreateCategory from './components/AdminVendors/createcategory';

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

export const initRouter = () => {

    const isAuthenticated = localStorage.getItem("user") || '';
    let isAdmin = '0';
    if(isAuthenticated !== ''){
        const AdminRest = JSON.parse(localStorage.getItem("user") || '');
        isAdmin = AdminRest.isAdmin;
    }
    root.render(
        <BrowserRouter basename={'/'}>
            <Routes >
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={ isAuthenticated !== '' ? <Home /> : <Login />} />
                    <Route path="/brokers" element={ isAuthenticated !== '' ? <Brokers /> : <Login />} />
                    <Route path="/resources" element={ isAuthenticated !== '' ?  <Resources /> : <Login />} />
                    <Route path="/vendors" element={ isAuthenticated !== '' ?  <Vendors /> : <Login />} />
                    <Route path="/adminvendors" element={ isAuthenticated !== '' && isAdmin === "1" ?  <AdminVendors /> : <Login />} />
                    <Route path="/createvendors" element={ isAuthenticated !== '' && isAdmin === "1" ?  <CreateVendors /> : <Login />} />
                    <Route path="/modifyvendor" element={ isAuthenticated !== '' && isAdmin === "1" ?  <ModifyVendor /> : <Login />} />
                    <Route path="/admincategories" element={ isAuthenticated !== '' && isAdmin === "1" ?  <AdminCategories /> : <Login />} />
                    <Route path="/delcat" element={ isAuthenticated !== '' && isAdmin === "1" ?  <DelCat /> : <Login />} />
                    <Route path="/createcategory" element={ isAuthenticated !== '' && isAdmin === "1" ?  <CreateCategory /> : <Login />} />

                    <Route path="/adminpanel" element={ isAuthenticated !== '' && isAdmin === "1" ?  <AdminPanel /> : <Login />} />
                    <Route path="/createuser" element={ isAuthenticated !== '' && isAdmin === "1" ?  <CreateUser /> : <Login />} />
                    <Route path="/modifyuser" element={ isAuthenticated !== '' && isAdmin === "1" ?  <ModifyUser /> : <Login />} />
                    <Route path="/changepwd" element={ isAuthenticated !== '' && isAdmin === "1" ?  <ChangePwd /> : <Login />} />
                    <Route path="/logout" element={isAuthenticated !== ''? <Logout />: <Login />} />
                    <Route path="/changepassword" element={ isAuthenticated !== '' ?  <ChangePwdUser /> : <Login />} />
                    <Route path="/profile" element={ isAuthenticated !== '' ?  <Profile /> : <Login />} />
                    <Route path="/register" element={ <RegisterUser />} />
                    <Route path="/maps" element={ <Maps />} />
                    <Route path="/eSignature" element={isAuthenticated !== '' ?  <Signature /> : <Login />} />
                    <Route path="/alleSignature" element={isAuthenticated !== '' && isAdmin === "1" ?  <AllSignature /> : <Login />} />
                    <Route path="/announces" element={isAuthenticated !== '' && isAdmin === "1" ?  <Announces /> : <Login />} />
                    <Route path="/resourcesadmin" element={ isAuthenticated !== '' && isAdmin === "1" ?  <ResourceAdmin /> : <Login />} />
            </Routes>
        </BrowserRouter>
    );
}
